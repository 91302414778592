@import "~vuexy/scss/bootstrap/_functions.scss";
@import "~vuexy/scss/core/variables/_variables";
@import "./_variables";
@import "~vuexy/scss/bootstrap/_variables.scss";



@mixin background(){
  background-image: url("images/blue-light.svg");
  background-color: white;
  /* Set background image to fixed (don't scroll along with the page) */
  background-attachment: fixed;

  /* Center the background image */
  background-position: bottom;

  /* Set the background image to no repeat */
  background-repeat: no-repeat;

  /* Scale the background image to be as large as possible */
  background-size: contain;
}

