.exercise-image {
  width: 6em;
  height: 6em;
  border-radius: 0.5em;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  margin: auto;

  img {
    width: 100%;

    &[src*="/logo."] {
      padding: 8px;
    }

  }

}

.exercise-name {
  word-break: break-word;
}

.pill-circle {
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.badge.exercise-category {
  color: $gray-700;
  background-color: #F4F4F4;
  margin-right: 0.25rem;

  &.clickable {
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}
