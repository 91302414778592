$popover-header-color: $primary;
$headings-color: $body-color;
$font-weight-normal: 500;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-bg: #F6F6F6;
$nav-tabs-link-active-border-color: #EBEBEB;
$nav-tabs-border-width: 1px;
$nav-tabs-link-active-color: #1E87F0;
$input-border-color: darken(#f8f8f8, 12%);
$input-border-width: 2px;
$input-color: darken(#f8f8f8, 60%);
$font-weight-bold: 600;
