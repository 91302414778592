@import "~theme/mixins";
@import "~vuexy/scss/bootstrap/mixins/breakpoints";

$nf-color: #0079d0;
$light-color: #e5f5ff;
$light-gray: #ebebeb;
$light-orange : #FFE4CA;

$light-border: 1px solid $light-gray;

// Small tablets and large smartphones (landscape view)
$sm-width: 576px;

// Small tablets (portrait view)
$md-width: 768px;

// Tablets and small desktops
$lg-width: 992px;

// Large tablets and desktops
$xl-width: 1200px;

$outline: #1e1e1e auto 1px;
@mixin orange-outline() {
  outline: $orange solid 5px;
  outline-offset: 10px;
}

// Small devices
@mixin sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
