@import "variables";

.App,
.modal {
  .spaced-md {
    @include md() {
      display: flex;

      & > * {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
