@import "~theme/main.scss";
@import "~theme/_footer.scss";
@import "variables";
@import "scss/utilities.scss";
@import '~react-bootstrap-country-select/dist/react-bootstrap-country-select.css';


html {
  scroll-behavior: smooth;

  body {
    .swal2-popup {
      width: 38rem;
      padding: 3rem;

      .swal2-actions {
        button {
          margin: 0.3125em;
        }
      }
    }
  }
}

section {
  width: 100%;
  padding: 0 0 2rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}


.btn,
p,
label,
td {
  font-weight: 500;
}

thead td {
  text-align: center;
}




footer {
  display: flex;
  justify-content: space-between;

  font-size: 0.9rem;
  @include sm() {
    font-size: 1rem;
  }

  .copyright {
    margin-right: 1rem;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.overflow-bottom {
  position: absolute;
  bottom: 0;
  height: 1.5em;
  background: linear-gradient(#ffffff00, #ffffffff);
  width: 100%;
  transition: opacity 0.5s;
  z-index: 20;
}


table.simple {
  width: 100%;

  &.no-border {
    td {
      border: none;
    }
  }

  td {
    padding: 1.5rem 0;

    border-top: solid 1px $light-gray;
    border-bottom: solid 1px $light-gray;

    &:first-child {
      font-weight: 700;
    }
  }
}

.horizontal-menu .App .header-navbar.PortalMenu .navbar-brand {
  line-height: 1;
}

.card .card {
  border: 1px solid $light-gray;
}

.card-header-tabs {
  margin-right: 0;
  margin-left: 0;
}

.btn-block {
  padding: 0.9rem;
}


.blink-3 {
  animation: blink 1s ease-in-out 3;
}

.pulse {
  animation: pulse-size 1s ease-in-out infinite alternate;
}

.pulse-small {
  animation: pulse-small 0.75s ease-in-out infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-size {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes pulse-small {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.btn.btn-sq {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  vertical-align: baseline;

  &.btn-lg {
    width: 2em;
    height: 2em;
  }
}
