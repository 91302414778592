@import "_mixins.scss";

footer {
  margin-bottom: 1em;

  a {
    color: $body-color;
  }

  ul {
    @extend .list-inline;
  }

  li {
    @extend .list-inline-item;
    list-style: none;
    display: inline-block;
  }
}
