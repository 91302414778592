// var for boxshadow of gradient buttons
// Buttons
.btn {
  // to change cursor of disabled button
  &:disabled{
    cursor: default;
  }
  // for white button
  &.btn-white{
    color: $body-color;
    &:hover,&:focus,&:active{
      color: $body-color !important;
    }
  }

  // box shadow for gradient colors
  &[class*="bg-gradient-"]:hover{
    box-shadow: $gradient-button-bs;
  }

  // For Boxshadow on hover of normal light, dark and secondary btn
  &.btn-secondary {
    color: $white;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($secondary, 1);
    }
  }
  &.btn-dark {
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($dark, 1);
    }
  }
  &.btn-light {
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($light, 1);
    }
  }

  // For Light,dark and secondary outline btns
  &.btn-outline-secondary,
  &.btn-outline-dark,
  &.btn-outline-light {
    border: 1px solid;
  }

  // Flat Secondary, Light and Dark
  &.btn-flat-secondary {
    &:hover {
      background-color: rgba($secondary, 0.1);
    }
    &:active,
    &:focus {
      background: $secondary
    }
  }

  &.btn-flat-dark {
    &:hover {
      background-color: rgba($dark, 0.1);
    }
    &:active,
    &:focus {
      background: $dark;
      color: $white;
    }
  }

  &.btn-flat-light {
    &:hover {
      background-color: rgba($light, 0.1);
    }
    &:active,
    &:focus {
      background: $light;
    }
  }

  // to remove extra spacing because of border in btn outline
  &[class*="btn-outline-"]{
    padding-top: calc(#{$btn-padding-y} - 1px);
    padding-bottom: calc(#{$btn-padding-y} - 1px);
    &.btn-sm{
      padding-top: calc(0.5rem - 1px);
      padding-bottom: calc(0.5rem - 1px);
    }
  }

  // Relief Buttons Secondary, Light and Dark
  &.btn-relief-secondary {
    background-color: $secondary;
    box-shadow: inset 0 -3px 0 0 rgba($pure-black, .2);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }
  &.btn-relief-light {
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .2);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }
  &.btn-relief-dark {
    background-color: $dark;
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, .1);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }

  // For btn-icon
  &.btn-icon {
    padding: .715rem .736rem;
  }
  &.btn-sm{
    &.btn-icon{
      padding: .5rem;
    }
  }
  &.btn-lg{
    &.btn-icon{
      padding: 1rem;
    }
  }

  // Remove focus/active focus outline
  &.btn-square {
    @include button-square-variant(0);
  }

  &.btn-round {
    @include button-round-variant(2rem);
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none !important;
  }
  &.btn-icon {
    i {
      font-size: 1.1rem;
    }

  }
}

// For White Btn
.btn.btn-outline-white,
.btn-white{
  &:hover{
    color: $black !important;
  }

}

// Added cursor pointer as in latest bootstrap do not have this
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

// For Waves Padding
.waves-button-input {
  padding: unset;
}
