@import "./mixins";
@import "~@fontsource/montserrat/latin.css";
@import "~@fontsource/montserrat/latin-ext.css";
@import "~vuexy/scss/bootstrap/bootstrap";
@import "~vuexy/scss/bootstrap-extended";
@import "~vuexy/scss/components";
@import "~vuexy/scss/colors";
@import "_nf_components.scss";

body {
  /* Set a specified height, or the minimum height for the background image */
  min-height: 500px;
}

.nf-bg-image {
  @include background();
}
